.Card {
    animation: 0.4s show ease-out;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--soft-pink-color);
    width: 92vw;
    height: 94%;
    border: solid var(--true-black-color) 2px;
    border-radius: 40px;
    // box-shadow: 3px 5px 0px var(--true-black-color);
    margin-bottom: 36px;
    user-select: none;
    // z-index: 0;
}

.Background {
    border-radius: 38px;
    opacity: 23%;
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: 0;
    pointer-events: none;
    mix-blend-mode: luminosity;
}

.Text {
    // word-break: break-all;
    overflow-wrap: break-word;
    position: relative;
    z-index: 1;
    width: 90%;
    font: var(--font-xl);
    margin: 24px 20px 12px 24px;
}

.CardImage {
    position: relative;
    z-index: 1;
    border-radius: 32px;
    width: 94%;
    margin-top: 12px;
    margin-bottom: 3%;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
}

.AdminCardImage {
    pointer-events: all;
}

.Container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 36px;
    z-index: 2;
}

.SwipeIcon {
    margin-top: 70%;
    width: 70%;
    z-index: 3;
    pointer-events: none;
}

.SwipeText {
    font: var(--font-xl);
    font-weight: 700;
    color: var(--true-white-color);
    z-index: 3;
}

.SmallCard {
    position: relative;
    animation: none;
    border-radius: 32px;
    position: relative;
    width: 100%;
    height: 97%;
    max-height: 100%;
    box-shadow: none;

}

.SmallBackground {
    opacity: 23%;
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: 0;
    pointer-events: none;
    mix-blend-mode: luminosity;
    border-radius: 30px;
}

.MicroCard {
    position:relative;
    width: 90px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: solid var(--true-black-color) 0.87px;
    border-radius: 16px;
    // box-shadow: 3px 5px 0px 0px var(--true-black-color);
}

.MicroCardImage {
    position: relative;
    z-index: 1;
    border-radius: 16px;
    width: 94%;
    margin-bottom: 2%;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
}

.MicroBackground {
    border-radius: 16px;
    opacity: 23%;
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: 0;
    pointer-events: none;
    mix-blend-mode: luminosity;
}

.MicroText {
    overflow-wrap: break-word;
    margin-top: 10px;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 9px;
    font: var(--font-xxs);
    color: var(--true-black-color);
    width: 80%;
    z-index: 1;
}

.blockedBlack{
    color: var(--ter-black-color);
}

.blockedWhite {
    color: var(--true-black-color);
}

.SmallText {
    margin: 16px auto 12px;
    font: var(--font-m)
}

.Wanted {
    background-color: var(--soft-orange-color);
}

.Icon {
    width: 24px;
}

.AdminInputText {
    background-color: none;
    font: var(--font-size-l);
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    resize: none;
}

.AdminText {
    overflow-wrap: break-word;
    position: relative;
    z-index: 1;
    min-height: 20%;
    width: 90%;
    height: auto;
    font: var(--font-xl);
    margin: 20px auto 10px auto;
}

.UserInfo {
    animation: showUser 0.5s;
    position: relative;
    display: flex;
    align-items: center;
    width: 95%;
    margin-left: 24px;
    margin-bottom: auto;
    height: 32px;
    z-index: 3;
    justify-content: space-between;
}

.UserInfoName {
    // width: 200px;
    margin-left: 8px;
    margin-right: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.UserInfoAvatar {
    width: 32px;
    height: 32px;
    border-radius: 12px;
    object-fit: cover;
}

.AddFriendButton {
    animation: showUser 0.5s;
    height: 27px;
    min-width: 74px;
    font: var(--font-xs);
    font-weight: 600;
    color: var(--sec-black-color);
    background: none;
    border: 1px solid rgba($color: #1F1F23, $alpha: 0.2);
    border-radius: 6px;
    margin-right: 24px;
}

.friend {
    background: rgba(220, 255, 162, 0.20);
    border: 1px solid var(--soft-green-color);
}

.ImageContainer {
    position: relative;
    z-index: 1;
    margin-top: 32px;
    margin-bottom: 12px;
    width: calc(100% - 24px);
    // height: 66%;
    flex-grow: 1;
    border: dashed 2px var(--ter-black-color);
    border-radius: 32px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--soft-gray-color);
}

.PickedImage {
    margin-top: 32px;
    border-radius: 32px;
    width: 94%;
    margin-bottom: 3%;
    overflow: hidden;
    object-fit: cover;
    align-self: center;
}

.Image {
    width: 32px;
    height: 32px;
    pointer-events: none;
}

.PickImageText {
    margin-left: 12px;
    text-align: center;
    // width: 68%;
    font-weight: 500;
    color: var(--ter-black-color)
}

.SmallLoader {
    margin-top: 0;
    left: calc(50% - 16px);
    top: 55%;
}

.MicroLoader {
    margin-top: 0;
    left: calc(50% - 12px);
    top: 55%;
}

.blockedImage {
    filter: grayscale(1);
}

.Yellow {
    background-color: #FFD027;
}

.Orange {
    background-color: var(--soft-orange-color);
}

.Pink {
    background-color: var(--soft-pink-color);
}

.Purple {
    background-color: #CBB9FC;
}

.Blue {
    background-color: #8BCEFD;
}

.Green {
    background-color: #D6F7CA;
}

.First {
    background-color: var(--first-card-color);
}

.Second {
    background-color:var(--second-card-color);
}

.Third {
    background-color:var(--third-card-color);
}

.Fourth {
    background-color:var(--fourth-card-color);
}

.Fifth {
    background-color:var(--fifth-card-color);
}

.Sixth {
    background-color:var(--sixth-card-color);
}

.Seventh {
    background-color:var(--seventh-card-color);
}

.Eighth {
    background-color:var(--eighth-card-color);
}

.Ninth {
    background-color:var(--ninth-card-color);
}

.Tenth {
    background-color:var(--tenth-card-color);
}

.Eleventh {
    background-color:var(--eleventh-card-color);
}

.Twelfth {
    background-color:var(--twelfth-card-color);
}

.Thirteenth {
    background-color:var(--thirteenth-card-color);
}

.Fourteenth {
    background-color:var(--fourteenth-card-color);
}

.White {
    background-color: var(--true-white-color);
}

.Black {
    background-color: var(--true-black-color);
}
// width: 50vw; height: 50%;  width: 92vw; height: 92% 
@keyframes show {
    from { opacity: 0; transform: scale(0.8) }
    to { opacity: 1; transform: scale(1) }
}

@keyframes showUser {
    from { opacity: 0 }
    to { opacity: 1 }
}

@media (max-width: 300px) {
    .Text {
        font: var(--font-l)
    }
}

@media (max-width: 364px) {
    .SmallText {
        font: var(--font-s);
    }
}

@media (min-width: 1023px) {
    .Card {
        width: 800px;
    }
    .SmallCard {
        width: 400px;
    }
  }

@media (max-height: 568px) {
    .Text {
        font: var(--font-l);
    }

    .SmallText {
        font: var(--font-s)
    }
    
}

@media (min-width: 1023px) {
    .Card {
        width: 92%;
        height: 92%;
    }
    .SmallCard {
        margin-left: auto;
        margin-right: auto;
    }
}
  