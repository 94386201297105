.SubmitWindow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: var(--true-white-color);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImageSubmit {
    animation: 0.5s showSubmit ease;
    width: 100px;
    height: 100px;
}

.TitleTextSubmit {
    animation: 0.5s showSubmit ease;
    margin-top: 32px;
    font: var(--font-xl);
    font-weight: 700;
    text-align: center;
}

.SecondTextSubmit {
    animation: 0.5s showSubmit ease;
    margin-top: 16px;
    text-align: center;
    font: var(--font-m);
    font-weight: 500;
}

.SubmitWindowButton {
    animation: 0.5s showSubmit ease;
    margin-top: 16px;
}

@media (min-width: 1023px) {
    .SubmitWindowButton {
        width: calc(680px - 24px);
    }
}

@keyframes showSubmit {
    from {opacity: 0; transform: translateY(30px) }
    to {opacity: 1; transform: translateY(0px) }
}