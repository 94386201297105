.Image {
    width: 100px;
    margin-top: 50%;
    margin-bottom: 32px;
}

.Text {
    text-align: center;
    width: 90%;
    // margin-top: 50%;
    // margin-left: 24px;
    font: var(--font-xl);
    font-weight: 700;
    margin-bottom: 32px;
}

.Button {
    color: black;
    font: var(--font-m);
    font-weight: 700;
    width: 80vw;
    height: 80px;
    border: 2px solid black;
    border-radius: 30px;
    background-color: #DCFFA2;
    box-shadow: 3px 5px 0px 0px black;
}

.SecondaryText {
    margin-top: 60px;
    text-align: center;
}