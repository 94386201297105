.RequestPage {
    // margin-left: -93%;
    position: relative;
    width: 98%;
    height: 100%;
    max-height: 100%;
    // max-height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
}

.TitleContainer {
    align-self:flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Title {
    margin-top: 15px;
    margin-left: 12px;
    font: var(--font-l);
    font-weight: 700;
}

.HelpContainer {
    margin-right: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--soft-gray-color);
    border-radius: 12px;
}

.Help {
    // margin-right: 20px;
    // margin-top: 14px;
    text-align: center;
    align-self: center;
    width: 24px;
    height: 24px;
    pointer-events: none;
}

.Button {
    animation: 0.5s show ease;
    margin-top: 16px;
    margin-bottom: 6px;
    width: 98%;
}

.CardContainer {
    position: relative;
    animation: 0.5s show ease;
    margin-top: 16px;
    width: 96%;
    height: calc(100% - 80px - 45px - 50px);
    border-radius: 40px;
    border: 2px solid var(--true-black-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ThumbsUp {
    position: absolute;
    width: 32px;
    height: 32px;
    rotate: -15deg;
    top: 20px;
    right: 20px;
}

.AcceptCard {
    background-color: rgb(220,	255, 162, 0.4);
    border: 2px solid #5BC470;
}

.AcceptImageContainer {
    border: dashed 2px 
}

.TextInput {
    overflow-wrap: break-word;
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
    width: 82%;
    // height: 20%;
    font: var(--font-xl);
    // width: 60%;
}

.ImageContainer {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 32px;
    margin-bottom: 12px;
    // height: 66%;
    flex-grow: 1;
    border: dashed 2px var(--ter-black-color);
    border-radius: 32px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--soft-gray-color);
}

.PickedImage {
    position: absolute;
    margin-top: 32px;
    border-radius: 32px;
    height: 55%;
    width: 94%;
    bottom: 3%;
    overflow: hidden;
    object-fit: cover;
    align-self: center;
}

.Image {
    width: 32px;
    height: 32px;
    pointer-events: none;
}

.Text {
    margin-left: 12px;
    text-align: center;
    // width: 68%;
    font-weight: 500;
    color: var(--ter-black-color)
}

.WarningShake {
    animation: horizontalShaking 0.2s infinite;
}

.FloatModal {
    width: 100vw;
    height: 90vh;
    height: 100dvh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.Content {
    position: relative;
    // animation: 0.5s showFloat;
    width: 100vw;
    height: 98%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background-color: var(--true-white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.magnifier {
    width: 24px;
    position: absolute;
    top: 33px;
    left: 28px;
    pointer-events: none;
}

.inputSearch {
    border: 2px solid var(--true-black-color);
    border-radius: 20px;
    width: calc(100% - 152px);
    height: 60px;
    padding-left: 20px;
    font: var(--font-m);
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
    outline: none;
    margin-left: 12px;
}

.SearchContainer {
    margin-top: 12px;
    display: flex;
    width: 100%;
    // justify-content: space-between;
}

.CrossButton {
    animation: showRightButton 0.2s ease-in-out;
    margin-left: 12px;
    margin-right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    background-color: var(--soft-gray-color);
}

.ImageArray {
    // animation: show 0.5s;
    margin-top: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 196px;
    gap: 1px 1px;
    grid-auto-rows: 196px;
    grid-auto-columns: 50% 50%;
    background-color: var(--true-white-color);
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    // touch-action: none;
    // touch-action: pan-y; //выключение всех тач экшнов кроме пролистывания по y
}

.ImageTenor {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.clearIcon {
    position: absolute;
    right: 100px;
    top: 32px;
    width: 24px;
}

.Loader {
    margin-top: 0;
}

// @keyframes showFloat {
//     from { opacity: 0; }
//     to {opacity: 1; }
// }

@keyframes horizontalShaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}

@keyframes show {
    from { opacity: 0}
    to { opacity: 1}
}

@media (max-width: 350px) {
    .TextInput {
        font: var(--font-l);
    }
}