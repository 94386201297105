.normal {
    --true-black-color: #1F1F23;
    --sec-black-color: #44444D;
    --ter-black-color: #8F8F9C;
    --true-white-color: #FFFFFF;

    //soft
    --soft-gray-color: #F1F1F1;
    --soft-blue-color: #DEF3F8;
    --soft-yellow-color: #F8F6E9;
    --soft-pink-color: #FCD8D8;
    --soft-orange-color: #FFCB11;
    --soft-green-color: #CEFF1A;


    //experimental
    --blue-color: #3A86E9;
    --green-color: #5BC470;
    --orange-color: #FFA237;
    --red-color: #EC465C;
    --purple-color: #C6B9FE;

    //random
    --first-card-color: #FF8A8A;
    --second-card-color: #FF94B0;
    --third-card-color: #FE964C;
    --fourth-card-color:#FEBA33;
    --fifth-card-color:#F2E124;
    --sixth-card-color: #D9E82E;
    --seventh-card-color: #87D94C;
    --eighth-card-color:#6BD66B;
    --ninth-card-color: #5FCDD9;
    --tenth-card-color: #6FC7F2;
    --eleventh-card-color: #7DABF0;
    --twelfth-card-color: #A1A7FF;
    --thirteenth-card-color:#CCB2FF;
    --fourteenth-card-color: #EAABFF;
}