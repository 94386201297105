.FriendsPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    // overflow-y:visible;
    overflow-x: hidden;
}

.Container {
    position: fixed;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--true-white-color);
    z-index: 4;
}


.Header {
    margin-left: 16px;
    // margin-right: auto;
    // margin-top: 17px;
}

.CloseButton {
    margin-left: 16px;
    margin-top: 12px;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}

.FriendsContainer {
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.EmptyFriends {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 80px;
}

.EmptyImage {
    width: 100px;
    height: 100px;
}

.EmptyText {
    margin-top: 32px;
    margin-left: 12px;
    margin-right: 12px;
}

.EmptySecondText {
    margin-top: 16px;
    // width: calc(100% - 24px);
    margin-left: 12px;
    margin-right: 12px;
}

.Button {
    margin-top: 24px;
    // width: calc(100% - 24px);
    height: 48px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonText {
    // width: 80%;
    margin-left: 10px;
    margin-right: 20px;
}

.Search {
    position: relative;
    width: calc(100% - 24px);
    // width: 100%;
    margin-top: 72px;
    // align-self: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SearchContainer {
    display: flex;
}

.inputSearch {
    border: 2px solid var(--true-black-color);
    border-radius: 20px;
    width: calc(100% - 54px);
    height: 60px;
    padding-left: 20px;
    font: var(--font-m);
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
    outline: none;
}

.error {
    border: 2px solid var(--red-color);
}

.onFocusSearch {
    width: calc(100% - 130px);
}

.magnifier {
    width: 24px;
    position: absolute;
    top: 20px;
    left: 20px;
    pointer-events: none;
}

.nicknameErrorText {
    margin-top: 8px;
    margin-left: 20px;
    width: 94%;
}

.errorIcon {
    position: absolute;
    right: 16px;
    top: 20px;
    width: 24px;
    filter: invert(47%) sepia(53%) saturate(6226%) hue-rotate(329deg) brightness(98%) contrast(89%);
}

.errorIconLeft {
    right: 90px;
}

.errorIconLeftValidate {
    right: 90px;
    filter: none;
}

.CrossButton {
    animation: showRightButton 0.2s ease-in-out;
    margin-left: 12px;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    background-color: var(--soft-gray-color);
}

.ButtonInviteFriends {
    margin-top: 24px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Copynickname {
    display: inline;
    color: var(--blue-color);
    font-weight: 700;
}

@keyframes showRightButton {
    from {transform: translateX(100px)}
    to {transform: translateX(0px);}
}

@media (min-width: 1023px) {
    .Container {
        width: 680px;
    }
}