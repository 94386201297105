.OfferCollectionModal {
    position: relative;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    background-color: var(--true-white-color);
}

.Text {
    margin-top: 32px;
    font: var(--font-xl);
    font-weight: 700;
    text-align: center;
    width: 90%;
    margin-bottom: 32px;
}

.whale {
    width: 100px;
    height: 100px;
}