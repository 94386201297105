.WantListItem {
    animation: show 0.5s;
    position: relative;
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    background-color: var(--true-white-color);
}

.Img {
    margin-left: 12px;
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    border-radius: 20px;
    object-fit: cover;
    pointer-events: none;
}

.Text {
    margin-left: 12px;
    margin-right: auto;
    // width: 60%;
    width: 60%; // TODO: разобраться с шириной текста
    overflow: hidden; /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis;
    word-break: break-word;
}

.Button {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    transition: all 0.05s linear;
    background-color: var(--soft-orange-color);
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 2px solid var(--true-black-color);
    box-shadow: 3px 5px 0px 0px var(--true-black-color);
}

.AcceptButton {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.05s linear;
    background-color: var(--soft-green-color);
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 2px solid var(--true-black-color);
    box-shadow: 3px 5px 0px 0px var(--true-black-color);
}

.accepted {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-color: var(--soft-green-color);
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 0;
    box-shadow: none
}

.wanted {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-color: var(--soft-orange-color);
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 0;
    box-shadow: none
}

.rejected {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    transition: all 0.05s linear;
    background-color: var(--soft-gray-color);
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 0;
    box-shadow: none
}

.RejectButtonActive {
    margin-right: 12px;
    background-color: var(--soft-gray-color);
    min-width: 40px;
    height: 40px;
    border-radius: 16px;
    border: 0;
}

.AcceptButton:active {
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
}

.Check {
    pointer-events: none;
    width: 24px;
    height: 24px;
}

.SettingsButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-right: 12px;
    border: none;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 16px;
    // background-color: red;
    background-color: var(--soft-gray-color);
}

.Inside {
    pointer-events: none;
    width: 24px;
    height: 24px;
}

.Dots {
    margin-top: 16px;
    // width: 94%;
    height: 0px;
    margin-left: 12px;
    margin-right: 12px;
    // background-color: black;
    border-bottom: 4px dotted #F1F1F1;
}

@keyframes show {
    from {opacity: 0; transform: translateY(20px);}
    to {opacity: 1; transform: translateY(0px);}
}