.Choosebar {
    animation: 0.5s show ease;
    position: relative;
    top: -11%;
    // margin-left: 3vw;
    margin-right: 10px;
    height: 0px;
    width: 92%;
    display:flex;
    // align-items: center;
    justify-content: center;
    // justify-content: space-around;
    // z-index: 11;
}

.Disabled {
    pointer-events: none;
}

// .Active {
//     transition: all 0.2s linear;
//     box-shadow: 0px 0px;
//     transform: translate3d(3px, 3px, 0px);
// }

@keyframes show {
    from { opacity: 0; transform: translateY(30px) }
    to { opacity: 1; transform: translateY(0px) }
}