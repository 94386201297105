.Container {
    position: absolute;
    // top: 80px;
    background-color: var(--true-white-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    overflow-y:visible;
    overflow-x: hidden;
    width: 95%;
    height: 100%;
    touch-action: none;
    touch-action: pan-y; //выключение всех тач экшнов кроме пролистывания по y
    z-index: 1;
}

.TextTime {
    margin-top: 32px;
    margin-bottom: 16px;
    margin-left: 14px;
}

.PopupText {
    width: 80%;
    margin-left: 10px;
}