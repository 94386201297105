.EmptyWantContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Whale {
    margin-top: 100px;
    height: 100px;
    width: 100px;
}

.TitleText {
    margin: 32px 12px 0;
}

.SecondText {
    margin: 16px 12px 0;
}

.Button {
    margin-top: 32px;
}